import VenueNow from "../Images/logo-rectangle-venuenow.png";
import "./HomeScreen.scss";
import Decomission from "../Images/icon-Decommission-Container.png";
import Rotation from "../Images/Back-in-Rotation-Container.png";

function HomeScreen({ decomission, readyForUse }) {
  return (
    <div className="home__base">
      <div className="home__title">
        <h1 className="home__title__text">
          Welcome, please select an option below.
        </h1>
      </div>
      <div className="home__image">
        <div className="home__image__bg" onClick={decomission}>
          <img
            className="home__image__logo"
            src={Decomission}
            alt="Decomission"
          ></img>
          <p className="home__image__message">Decomission package.</p>
        </div>
        <div className="home__image__bg" onClick={readyForUse}>
          <img
            className="home__image__logo"
            src={Rotation}
            alt="Back-in-Rotation"
          />
          <p className="home__image__message">Scan back into rotation.</p>
        </div>
      </div>
      <div className="home__footer">
        <img className="home__footer__logo" src={VenueNow} alt="VenueNow" />
      </div>
    </div>
  );
}

export default HomeScreen;
