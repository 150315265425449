import React from "react";
import "./FinishPage.scss";

import TickLogo from "../Images/icon-Success.png";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

function ReadyFinishPage() {
  return (
    <div className="finish__base">
      <div className="finish__title">
        <h1 className="finish__title__text">
          Packages ready for use! Returning to homescreen.
        </h1>
      </div>
      <div className="finish__image">
        <img className="finish__image__logo" src={TickLogo} alt="Tick" />
      </div>
      <div className="finish__footer">
        <img className="finish__footer__logo" src={VenueNow} alt="VenueNow" />
      </div>
    </div>
  );
}

export default ReadyFinishPage;
