import { React, useState, useEffect } from "react";

import HomeScreen from "./Components/HomeScreen";
import Decomission from "./Decomission";
import ReadyForUse from "./ReadyForUse";

import Sfx from "./Sounds/Sfx.mp3";
import useSound from "use-sound";

function HomePage() {
  const [activeRoute, setActiveRoute] = useState("HomePage");

  const [play] = useSound(Sfx);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  const goHome = () => {
    play();
    setActiveRoute("HomePage");
  };
  const decomission = () => {
    play();
    setActiveRoute("Decomission");
  };
  const readyForUse = () => {
    play();
    setActiveRoute("ReadyForUse");
  };

  return (
    <div className="body">
      {activeRoute === "HomePage" && (
        <HomeScreen decomission={decomission} readyForUse={readyForUse} />
      )}
      {activeRoute === "Decomission" && <Decomission goHome={goHome} />}
      {activeRoute === "ReadyForUse" && <ReadyForUse goHome={goHome} />}
    </div>
  );
}

export default HomePage;
