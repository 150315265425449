import React, { useState, useEffect } from "react";
import axios from "axios";
import HomePage from "./HomePage";
import "./App.scss";
import Background from "./Components/Background";

function App() {
  const [errorMessages, setErrorMessages] = useState({});
  const [tokenActive, setTokenActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    const token = localStorage.getItem("token");
    if (token) {
      setTokenActive(true);
    } else {
      setTokenActive(false);
    }
  }, []);

  window.setInterval(function () {
    let date = new Date();
    console.log(date.getHours);
    if (date.getHours() === 4 && date.getMinutes() === 0) {
      window.location.reload();
    }
  }, 60000);

  const errors = {
    cateringId: "Field must be a number",
    serial: "Field must be a number",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let { cateringId, serial } = document.forms[0];

    // Compare user info
    if (cateringId.value && serial.value) {
      let iscateringId = /^\d+$/.test(cateringId.value);
      let isSerialNum = /^\d+$/.test(serial.value);
      if (!iscateringId) {
        // Invalid Door Number
        setErrorMessages({ name: "cateringId", message: errors.doorEntry });
      } else if (!isSerialNum) {
        // Invalid Serial Number
        setErrorMessages({ name: "serial", message: errors.serial });
      } else if (iscateringId && isSerialNum) {
        setIsSubmitted(true);
      }
    }

    if (isSubmitted) {
      const loginFormData = {
        cateringDeviceId: parseInt(cateringId.value),
        serialNumber: parseInt(serial.value),
      };
      try {
        const response = await axios({
          method: "post",
          data: loginFormData,
          url: "https://api.venuenow.tech/auth/catering",
          headers: { "Content-Type": "application/json" },
        });
        localStorage.setItem("token", response.data.token);
        setTokenActive(true);
        console.log(response);
      } catch (error) {
        console.log(error);
        setLoginError(true);
      }
    }
  };

  // Error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // Login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Catering Id</label>
          <input type="text" name="cateringId" required />
          {renderErrorMessage("cateringId")}
        </div>
        <div className="input-container">
          <label>Serial Number</label>
          <input type="text" name="serial" required />
          {renderErrorMessage("serial")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      <Background>
        <div className={`${tokenActive ? "'" : "login-form"}`}>
          <div className={`${tokenActive ? "title-hide" : "title"}`}>
            Sign In
          </div>
          {tokenActive ? <HomePage /> : renderForm}
        </div>
        {loginError && (
          <div
            className={`${tokenActive ? "login-error-hide" : "login-error"}`}
          >
            Login Failed - Check credentials and try again
          </div>
        )}
      </Background>
    </>
  );
}

export default App;
